module.exports = {
    dataCache: {
        current_admin: {}
    },
    key: {
        storage: {
            current_account_login: "CURRENT_USER_LOGIN",
            current_account: "CURRENT_USER",
        },
        data_news: 'DATA_NEWS',
        data_training: 'DATA_TRAINING',
        data_cooperactive: 'DATA_COOPERACTIVE',
        data_doctor: 'DATA_DOCTOR',
        data_landingpage: 'DATA_LANDINGPAGE',
        data_slide_place: 'DATA_SLIDE_PLACE',
        data_menu: 'DATA_MENU',
    },
    action: {
        action_user_login: "ACTION_USER_LOGIN",
        action_user_logout: "ACTION_USER_LOGOUT",
        action_user: "ACTION_USER",
        list_page: "LIST_PAGE",
        list_slide_item: "LIST_SILDE_ITEM",
        list_slide_page: "LIST_SILDE_PAGE",
        list_training: "LIST_TRAINING",
        list_user_admin: "LIST_USER_ADMIN",
    },
    message: {
        user: {
            create_error: "Tạo mới tài khoản không thành công!",
            update_error: "Cập nhật tài khoản không thành công!",
            error_code_2: "SĐT đã được sử dụng trong hệ thống. Vui lòng sử dụng SĐT khác!",
            error_code_3: "Email đã được sử dụng trong hệ thống. Vui lòng sử dụng Email khác!",
            error_code_4: "Số văn bằng chuyên môn đã tồn tại trên hệ thống. Vui lòng sử dụng Số văn bằng chuyên môn khác!",
            error_code_5: "Username đã tồn tại trên hệ thống. Vui lòng sử dụng Username khác!",
        }, post: {
            approved_success: "Duyệt câu hỏi và gán cho bác sĩ thành công!",
            approved_error: "Duyệt câu hỏi không thành công!",
        },
        hospital: {
            create_error: "Tạo mới tài khoản không thành công!",
            update_error: "Cập nhật tài khoản không thành công!",

        }
    },
    api: {
        user: {
            search: "/user/search",
            login: "/user/login",
            logout: "/user/logout",
            block: "/user/block",
            create: "/user/create",
            update: "/user/update",
            reset: "/user/reset-password",
            active: "/user/set-active",
            detail: "/user/get-detail",
            updatePassword: "/user/update-password",
            updateEmail: "/user/update-email",
            getListBySpecialist: "/user/getListBySpecialist",
        },
        userAccess: {
            getAccess: "/user-access-count/get-access",
            totalCount: "/user-access-count/total-count",
            onlineCount: "/user-access-count/update-online-count",
            create: "/user-access/create"
        },
        menu: {
            search: "/menu/search",
            create: "/menu/create",
            update: "/menu/update",
            delete: '/menu/delete',
            active: '/menu/active',
            getDetail: '/menu/detail',
            getByGroup: '/menu/get-by-group',
            getHearderMenu: '/menu/get-hearder-menu',
        },
        service: {
            search: "/service/search",
            create: "/service/create",
            update: "/service/update",
            delete: '/service/delete',
        },
        news: {
            search: "/news/search",
            create: "/news/create",
            update: "/news/update",
            delete: '/news/delete',
            getDetail: "/news/get-all",
            getByAlias: "/news/detail-page-by-link"
        },
        doctor: {
            search: "/team_of_experts/search",
            create: "/team_of_experts/create",
            update: "/team_of_experts/update",
            delete: '/team_of_experts/delete',
            getDetail: "/team_of_experts/get-all",
            getByAlias: "/team_of_experts/detail-page-by-link"
        },
        page: {
            search: "/page/search-page",
            create: "/page/create-page",
            update: "/page/update-page",
            delete: '/page/delete-page',
            getDetail: "/page/detail",
            getByAlias: "/page/detail-page-by-link",
        },
        landingPage: {
            search: "/landing_page/search",
            create: "/landing_page/create",
            update: "/landing_page/update",
            delete: '/landing_page/delete',
        },
        cooperactive: {
            search: "/cooperative_basis/search",
            create: "/cooperative_basis/create",
            update: "/cooperative_basis/update",
            delete: '/cooperative_basis/delete',
        },
        training: {
            search: "/training/search",
            create: "/training/create",
            update: "/training/update",
            delete: '/training/delete',
            getDetail: "/training/get-all",
            getByAlias: "/training/detail-page-by-link"
        },
        image: {
            upload: "/image/upload"
        },
        slideItem: {
            search: "/slide-item/search",
            create: "/slide-item/create",
            update: "/slide-item/update",
            delete: '/slide-item/delete',
        },
        slide: {
            search: "/slide-page/search",
            create: "/slide-page/create",
            update: "/slide-page/update",
            delete: '/slide-page/delete',
        },
        slidePlace: {
            search: "/slide-place/search",
            create: "/slide-place/create",
            update: "/slide-place/update",
            delete: '/slide-place/delete',
        },
    }
}